var slideIndex = 0;
var autoSlide = true;
var slideTimer = undefined;
// showDivs(slideIndex);

var badge1 = document.getElementById('badge1');
badge1.addEventListener("click", function(e) {
    currentDiv(1)
}, false);

var badge2 = document.getElementById('badge2');
badge2.addEventListener("click", function(e) {
    currentDiv(2)
}, false);
var badge3 = document.getElementById('badge3');
badge3.addEventListener("click", function(e) {
    currentDiv(3)
}, false);

function plusDiv(n) {
  autoSlide = false;
  showDivs((slideIndex += n));
}

function currentDiv(n) {
  clearTimeout(slideTimer);
  autoSlide = false;
  showDivs((slideIndex = n));
}

function showDivs(n) {
  var i;
  var x = document.getElementsByClassName("mySlides");
  var dots = document.getElementsByClassName("demo");
  if (n > x.length) {
    slideIndex = 1;
  }
  if (n < 1) {
    slideIndex = x.length;
  }
  for (i = 0; i < x.length; i++) {
    x[i].style.display = "none";
  }
  for (i = 0; i < dots.length; i++) {
    dots[i].className = dots[i].className.replace(" w3-white", "");
  }
  x[slideIndex - 1].style.display = "block";
  dots[slideIndex - 1].className += " w3-white";
}

function autoslide() {
  showDivs((slideIndex += 1));
  if (autoSlide) {
    slideTimer = setTimeout(autoslide, 15000); // Change image every 2 seconds
  }
}
autoslide();